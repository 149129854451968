.register {
    width: 100%;
}
.box-form {
    width: 100%;
    margin: 30px;
    border: 1px solid rgba(128, 128, 128, 0.151);
    padding: 10px;
    border-radius: 20px;
}
.reg-client {
    margin-top: 10px;
    font-size: 17pt;
    color: white;
    padding: 10px;
    border-radius: 20px;
    background: rgb(153, 0, 255);
    box-shadow: 2px 4px 20px rgba(0,0,0,0.2)
}
.box-input-data {
    padding: 15px;
    border-radius: 20px;
    width: 300px;
}
.data-user {
    font-size: 11pt;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.599);
}
.box-data-user {
    border: 1px solid rgba(128, 128, 128, 0.151);
    border-radius: 20px;
    padding: 20px;
}
.name-user {
    margin: 0;
    font-size: 8pt;
    margin-top: 10px;
}
#input-data-user {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.123);
    border-radius: 0;
    font-size: 10pt;
    padding: 0
}
#descClient {
    border-radius: 20px;
    margin-top: 10px;
    height: 150px;
}
.box-img-perfil {
    margin-top: 20px;

}
.img-perfil-user {
    background: gray;
    border-radius: 60px;
    height: 80px;
    width: 80px;
    margin-right: 20px;
    margin-left: 50px;
}
.img-name {
    font-size: 8pt;
}
.data-addr {
    font-size: 11pt;
    font-weight: bold;
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.599);
}


#btn-save {
    background: rgba(153, 0, 255, 0.7);
    color: white;
    height: 40px;
    border-radius: 60px;
    margin-top: 20px;
    box-shadow: 2px 4px 10px rgba(0,0,0,0.2);
}

.save-loading {
    background: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    position: absolute;
}
.loading-gif {
    height: 30px;
    margin-top: 20%;
}

@media (max-width:1200px) {
    .box-form {
        width: 85vw;
        margin: 10px;
        border: 1px solid rgba(128, 128, 128, 0.151);
        padding: 20px;
        border-radius: 20px;
        /* background: red */
    }
    /* .register {
        background: gray;
        width: 100%;
        padding-left: 30px
    } */
}

@media (max-width:992px) {
    .register {
        width: 50%;
    }
    .box-form {
        /* width: 50%; */
        margin: 30px;
        box-shadow: 1px 5px 10px rgba(128, 128, 128, 0.951);
        padding: 10px;
        border-radius: 20px;
        margin-bottom: 90px;
    }
}