.menu {
    height: 100vh;
    border-top-right-radius: 40px;
    position: fixed;
}
.list-menu {
    background: rgb(153, 0, 255);
    padding-top: 30px; 
    padding-bottom: 30px; 
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
}
#icon-menu-logo {
    color: black;
    margin-top: 40px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 40px;
    padding-left: 18px;
    font-size: 15pt;
    padding-right: 18px;
    background: white;
    border-radius: 60px;
    height: 55px;
    width: 55px;
}
#icon-menu {
    color: black;
    margin-top: 40px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 40px;
    padding-left: 18px;
    font-size: 15pt;
    padding-right: 18px;
    background: white;
    border-radius: 60px;
    height: 55px;
    width: 55px;
}
.icon-menu {
    margin-top: 25px;
    margin-bottom: 25px;
}
.icon-menu-link {
    padding: 10px;
}
.icon-menu .icon-menu-link {
    color: white;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 14pt;
    border-left: 3px solid rgba(0, 0, 0, 0);
    transition: 0.2s;
}
.icon-menu .icon-menu-link:hover {
    background: rgba(0, 0, 0, 0.26);
    transition: 0.2s;
    cursor: pointer;
}
.activeLink {
    background: rgba(0, 0, 0, 0.26);
    padding: 10px;
    box-shadow: 1px 5px 24px rgba(0, 0, 0, 0.137);
}

/* NETBOOK */
@media (max-width:1200px){
    .menu {
        height: 100vh;
        border-top-right-radius: 0px;
        position: fixed;
        background: rgb(153, 0, 255);
    }
    .list-menu {
        padding-top: 0px; 
        padding-bottom: 30px; 
        border-top-right-radius: 60px;
        border-bottom-right-radius: 0px;
    }
}
@media (max-width: 900px){
    .menu {
        height: 0%;
        position: auto;

    }
    .list-menu {
        background: rgb(153, 0, 255);
        padding: 10px;
        position: fixed;
        bottom: 0;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0
    }
    .icon-menu {
        display: table-cell;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .icon-responsive {
        font-size: 17pt;
    }
    #icon-menu-logo{
        color: transparent;     
        background: transparent;
    }
    .icon-menu {
        margin-top: 0px;
        padding-bottom: 0px;
        margin-left: 10px;
    }
    .activeLink {
        background: rgba(0, 0, 0, 0);
        padding: 0px;
        border-bottom: 5px solid white;
        box-shadow: 1px 5px 24px rgba(128, 127, 127, 0);
    }
    .icon-menu-link {
        padding: 10px;
    }
    .icon-menu .icon-menu-link {
        color: white;
        font-size: 14pt;
        border-left: 3px solid rgba(0, 0, 0, 0);
        transition: 0.2s;
    }
    .icon-menu .icon-menu-link:hover {
        background: rgba(0, 0, 0, 0);
        transition: 0.2s;
        cursor: pointer;
    }
}