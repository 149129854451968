.login {
    background-image: url('https://appmonet.com/wp-content/uploads/2020/01/background-part-1-1.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 150%;
    height: 100vh;
    /* background: rgb(164, 15, 233); */
}
#prev-home {
    color: white;
    position: absolute;
    top: 30px;
    left: 30px;
    border-radius: 0px;
    font-size: 18pt;
    background: transparent;    
    transition: 0.3s;
}
#prev-home:hover {
    color: black;
    border-radius: 60px;
    background: white;    
    transition: 0.3s;
}
.box-login {
    height: 500px;
    width: 500px;   
    margin-top: 80px;
    border-radius: 16px;
    padding: 40px;
    box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.553);
    background: white;
}
.title-login {
    font-weight: bold;
    font-size: 15pt;
    margin-top: 40px;
    margin-bottom: 0;
}
.title-login-subtitle {
    font-size: 8pt;
    color: rgba(0, 0, 0, 0.644);
    margin-bottom: 40px;
}
.box-input {
    padding: 10px;  
    margin-top: -10px;
}
.title-input {
    font-size: 8pt;
    color: rgba(0, 0, 0, 0.644);
    margin-bottom: 5px;
}
#btn-login {
    width: 200px;
    border-radius: 60px;
    margin-top: 30px;
}
.iconlogo {
    font-size: 25pt;
    margin-bottom: -20px;
}
.box-info-error {
    padding: 10px;
    background: white;
    color: rgba(0, 0, 0, 0.486);
    text-align: center;
    width: 1000px;
    border-radius: 8px;
    font-size: 11pt;
    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.452);
}
#input-login {
    padding: 10px;
    border-radius: 60px;
    font-size: 9pt;
}

/* NETBOOK */
@media (max-width:1200px){ 
    .login {
        background-image: url('https://appmonet.com/wp-content/uploads/2020/01/background-part-1-1.png');
        background-repeat: no-repeat;
        background-position: top right;
        height: 100vh;
    }
    .box-login {
        height: auto;
        width: auto;   
        margin-top: 40px;
        border-radius: 16px;
        padding: 40px;
        box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.553);
        background: white;
    }
    .title-input {
        font-size: 8pt;
        color: rgba(0, 0, 0, 0.844);
        margin-bottom: 5px;
    }
}

/* MOBILE, LANDSCAP, TABLET */
@media (max-width:992px){ 
    .login {
        background-image: url('https://appmonet.com/wp-content/uploads/2020/01/background-part-1-1.png');
        background-repeat: no-repeat;
        background-position: top right;
        height: 100%;
    }
    .box-login {
        height: 80%;
        width: 100%;   
        margin-top: 40px;
        border-radius: 20px;
        padding: 20px;
        margin: 20px;
    }
    .iconlogo {
        font-size: 30pt;
        margin-top: 90px;
    }
    .title-login {
        font-weight: bold;
        font-size: 24pt;
        margin-top: 20px;
        margin-bottom: 0;
        text-align: center;
    }
    .title-login-subtitle {
        font-size: 10pt;
        text-align: center;
        /* margin-bottom: 40px; */
    }
    .title-input {
        font-size: 10pt;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.744);
        margin-bottom: 5px;
    }
    .box-info-error {
        padding: 10px;
        background: white;
        color: rgba(0, 0, 0, 0.486);
        text-align: center;
        width: 100%;
        border-radius: 8px;
        font-size: 11pt;
        box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.452);
    }
    .snackbar {
        width: 95%
    }
    #prev-home {
        display: none
    }
    #input-login {
        padding: 13px;
        font-size: 13pt;
    }
}