.dashboard {
    height: 100vh;
    width: 100%;
    /* background: white; */
}
.info-welcome {
    margin-top: 50px;
}
.dashboard-title {
    font-size: 20pt;
    font-weight: bold;
    margin: 0px;
    color: rgba(0, 0, 0, 0.689);
}
.bi {
    color: rgb(153, 0, 255);
    font-weight: bold;
    font-size: 20pt;
    padding-left: 5px;
}
.date {
    margin-top: -10px;
    padding: 10px;
    background: rgb(153, 0, 255);
    border-radius: 60px;
    color: white;
    width: 200px;
    text-align: center;
    font-weight: bold;
    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.205);
}
.img-dash {
    height: 560px;
    margin-top: -100px;
}
.whatsdo {
    /* margin-left: 50px; */
    margin-top: 30px;
    font-weight: bold;
    font-size: 12pt;
}
#btn-option-dashboard {
    margin-top: 10px;
    border-radius: 5px;
    width: 400px;
    text-align: left;
    padding: 10px;
    transition: 0.2s;
}
#btn-option-dashboard:hover {
    transform: scale(1.0200);
    padding-left: 20px;
    transition: 0.2s;
}

/* NETBOOK */
@media (max-width:1200px){
    .dashboard {
        height: 100vh;
        width: 100%;
        margin-left: 85px;
    }
    .img-dash {
        height: 460px;
        margin-top: -80px;
    }
    .info-welcome {
        margin-top: 50px;
    }
}


/* MOBILE, LANDSCAP, TABLET */
@media (max-width:992px){
    .dashboard {
        height: 100vh;
        width: 100%;
        /* background: white; */
        padding: 0;
        margin: 0;
    }
    .wrap {
        flex-wrap: wrap;
    }
    .dashboard-title {
        font-size: 26pt;
        font-weight: bold;
        margin: 0px;
        color: rgba(0, 0, 0, 0.689);
    }
    .date {
        margin-top: -10px;
    } 
    .whatsdo {
        margin-top: 30px;
        font-weight: bold;
        font-size: 18pt;
        color: rgba(0, 0, 0, 0.689);
    }
    #btn-option-dashboard {
        font-size: 12pt;
        box-shadow: 1px 5px 10px rgba(0,0,0,0.7);
        width: 100%;
    }
    .img-dash {
        height: auto;
        width: 100%;
        margin-top: 0px;
    }
}