#btn-save_ {
    background: rgba(153, 0, 255, 0.7);
    color: white;
    height: 40px;
    border-radius: 60px;
    margin-top: 20px;
    box-shadow: 2px 4px 10px rgba(0,0,0,0.2);
}
#input-user1, #input-user2, #input-user3, #input-user4, #input-user5, #input-user6 {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.123);
    border-radius: 0;
    font-size: 10pt;
    padding: 0
}