.list {
    width: 100%;
    height: 100vh;
}
.header-list {
    width: 100%;
    height: 150px;
    background: rgb(169, 5, 245);
}
.header-bottom {
    width: 100%;
    height: 8   0ch;
    /* padding: 90px; */
}
.box-data-info-length {
    background: white;
    width: 150px;
    height: 150px;
    margin-top: 50px;
    margin-right: 20px;
    border-radius: 30px;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.205);
    padding: 20px;
}
.box-data-info-length-user {
    background: rgb(169, 5, 245);
    width: 150px;
    height: 150px;
    margin-top: 50px;
    margin-right: 20px;
    border-radius: 30px;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.205);
    padding: 20px;
}
/* CLIENTES */
.list-title {
    font-size: 9pt;
}
.icon-db {
    font-size: 12pt;
    margin-right: 5px;
}
.bar_ {
    height: 2px;
    width: 27px;
    background: rgb(169, 5, 245);
    margin-top: -5px;
    border-radius: 160px;
}
.qtd-list {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 10pt;
    margin-top: 40px;
}
.qtd-value {
    font-size: 10pt;
    font-weight: bold;
}
/* USER */
.list-title-user {
    font-size: 9pt;
    color: white;
}
.icon-db-user {
    font-size: 12pt;
    color: white;
    margin-right: 5px;
}
.bar-user {
    height: 2px;
    width: 27px;
    background: rgb(169, 5, 245);
    margin-top: -5px;
    color: white;
    border-radius: 160px;
}
.qtd-list-user {
    margin-bottom: 5px;
    font-weight: bold;
    color: white;
    font-size: 10pt;
    margin-top: 40px;
}
.qtd-value-user {
    font-size: 10pt;
    color: white;
    font-weight: bold;
}
.div-input-search {
    height: 50px;
    background: white;
    width: 500px;
    border-radius: 60px;
    margin-top: 11%;
    padding: 5px;
    box-shadow: 1px 5px 10px rgba(0,0,0,0.2)
}
#input-search {
    border: 0 solid transparent;
    background: transparent;
}
.input-icon {
    font-size: 16pt;
    margin: 8px;
    color: rgba(0,0,0,0.6);
}


.div-list-client {
    padding-top: 90px;
}
.box-clientes {
    box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.096);
    width: 300px;
    border-radius: 15px;
    padding: 20px;
    margin: 20px;
}
.p-cliente-nome {
    font-weight: bold;
    font-size: 15pt;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.664);
}
.p-cliente-cep {
    padding: 5px;
    background: rgb(169, 5, 245);
    border-radius: 90px;
    width: 80px;
    font-weight: bold;
    font-size: 8pt;
    box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.196);
    color: white;
    text-align: center;
    margin-top: 5px;
}
.p-cliente-desc {
    font-size: 9pt;
    /* background: rgb(169, 5, 245); */
    /* word-break: break-all; */
    height: 80px;
    /* width: 100% */
}
.p-cliente-data {
    font-size: 8pt;
}
.p-cliente-hora {
    font-size: 8pt;
    margin-top: -12px;
    width: 100px;
}
.bar-client {
    background: rgb(169, 5, 245);
    width: 50px;
    height: 1px;
    margin-top: -12px;
}
.perfil-img-client {
    height: 30px;
    width: 30px;
    border-radius: 60px;
}

#plus {
    background: rgb(169, 5, 245);
    color: white;
    border-radius: 70px;
}
.box-loading {
    position:relative;
    width:100%;
    text-align:center;
}
#loading {
    height: 25px;
}
.p-cliente-datacadastramento {
    font-size: 8pt;
    font-weight: bold;
    margin-bottom: 3px;
}
.center {
    margin: 0 auto;
}
.empty {
    height: 300px;
}
.empty-reg {
    text-align: center;
    font-weight: bold;
}
::-webkit-scrollbar {
    display: none;
}
.desc-tolow {
    background: blue;
    width: 200px;
}

@media (max-width:1200px) {
    .header-list {
        padding-left: 70px;
        padding-right: 50px
    }
    .div-input-search {
        margin-top: 14%;
        padding: 5px;
        box-shadow: 1px 5px 10px rgba(0,0,0,0.2)
    }
    .box-clientes {
        box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.096);
        width: 350px;
        border-radius: 15px;
        padding: 20px;
        margin-top: 10px;
        margin-left: 90px;
    }
}

@media (max-width:900px) {
    .box-clientes {
        box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.096);
        width: 300px;
        border-radius: 15px;
        padding: 20px;
        margin: 20px;
    }
    .header-list {
        margin-bottom: 200px;
    }
    .p-cliente-desc {
        font-size: 9pt;
        /* width: 100%; */
        word-break: break-all;
        height: 80px;
    }
    .desc-box-clientes {
        width: 100%;
    }
    .div-list-client {
        padding-top: 30px;
        flex-wrap: wrap;
    }
    .div-box-clientes {
        margin-bottom: 70px;
    }
    .div-input-search {
        height: 50px;
        background: white;
        width: 100%;
        border-radius: 60px;
        margin-top: 5%;
        padding: 5px;
        box-shadow: 1px 5px 10px rgba(0,0,0,0.2)
    }
    /* #input-search {
        width: 100%
    } */
    .box-data-info-length-user {
        width: 100vw;
        /* padding-left: 100px;
        padding-right: 100px; */
        border-radius: 0px;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.205);
        padding: 20px;
    }
}