.edit {
    width: 100%;
}
.box-form-edit {
    width: 100%;
    margin: 0px;
    /* border: 1px solid rgba(128, 128, 128, 0.151); */
    padding: 20px;
    border-radius: 20px;
}
.reg-client-edit {
    margin-top: 10px;
    font-size: 17pt;
    color: white;
    padding: 10px;
    border-radius: 20px;
    background: rgb(153, 0, 255);
    box-shadow: 2px 4px 20px rgba(0,0,0,0.2);
    width: 100%;
}
.box-input-data {
    padding: 15px;
    border-radius: 20px;
    width: 300px;
}
#descricao-edit {
    width: 100%;
    height: 150px;
}
.data-user-edit {
    font-size: 11pt;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.599);
}
.box-data-user-edit {
    border: 1px solid rgba(128, 128, 128, 0.151);
    border-radius: 20px;
    padding: 20px;
    width: 100%
}
.name-user-edit {
    margin-top: 17px;
    font-size: 8pt;
    margin-bottom: 5px;
}
#input-data-user-edit1, #input-data-user-edit2, #input-data-user-edit3, #input-data-user-edit4, #input-data-user-edit5, #input-data-user-edit6, #input-data-user-edit7, #input-data-user-edit8, #input-data-user-edit9 {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.123);
    border-radius: 0;
    font-size: 10pt;
    padding: 0;
    width: 100%;
}
#btn-upload {
    border-radius: 60px;
}
.box-img-perfil {
    margin-top: 20px;

}
.img-perfil-user {
    background: gray;
    border-radius: 60px;
    height: 80px;
    width: 80px;
    margin-right: 20px;
    margin-left: 50px;
}
.img-name {
    font-size: 8pt;
}
.data-addr {
    font-size: 11pt;
    font-weight: bold;
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.599);
}


#btn-save {
    background: rgba(153, 0, 255, 0.7);
    color: white;
    height: 40px;
    border-radius: 60px;
    margin-top: 20px;
    box-shadow: 2px 4px 10px rgba(0,0,0,0.2);
    transition: 0.2s;
}
#btn-exit {
    background: rgba(0,0,0,0.5);
    color: white;
    height: 40px;
    border-radius: 60px;
    margin-top: 20px;
    box-shadow: 2px 4px 10px rgba(0,0,0,0.2);
    margin-left: 15px;
    transition: 0.2s;
}
#btn-save:hover, #btn-exit:hover {
    transition: 0.2s;
    transform: scale(1.0500);
}

.save-loading {
    background: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    position: absolute;
}
.loading-edit {
    width: 100vw;
    height: 100%;
    background: rgba(0,0,0,0.7);
    position: fixed;
    right: 0;
    top: 0;
}
.loading-edit-gif {
    height: 30px;
    margin-top: 20%;
}


@media (max-width:1200px) {
    .name-user-edit {
        margin-top: 17px;
        font-size: 8pt;
        margin-bottom: 5px;
        width: 100%
    }
    #descricao-edit {
        width: 100%;
        height: 150px;
    }
}

/* RSPONSIVE MOBILE */
@media (max-width:992px){
    .edit {
        width: 95%;
    }
    #descricao-edit {
        width: 100%;
        word-break: break-all;
        
    }
    .box-data-user-edit {
        border: 1px solid rgba(128, 128, 128, 0.151);
        border-radius: 20px;
        padding: 20px;
        width: 100%;
        flex-wrap: row
    }
    #btn-upload {
        width: 100%;
    }
}