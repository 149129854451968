.content {
    width: 350px;
    padding: 30px;
}
.content-nome {
    font-weight: bold;
    font-size: 14pt;
    margin-bottom: 2;
}
.content-cep {
    padding: 5px;
    font-size: 10pt;
    border-radius: 60px;
    background: rgb(169, 5, 245);
    color: white;
    width: 80px;
    text-align: center;
}
.content-desc {
    font-size: 12pt;
    margin-bottom: 2;
}
.content-img   {
    height: 40px;
    width: 40px;
    border-radius: 60px;
}
.box-addr {
    padding: 5px;
    border-radius: 10px;
    background: rgb(169, 5, 245);
    margin-top: 10px;
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.43);
    padding: 10px;
}
.box-cad {
    padding: 5px;
    border-radius: 10px;
    /* background: rgb(169, 5, 245); */
    margin-top: 10px;
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.23);
    padding: 10px;
}
.addr-end {
    font-weight: bold;
    color: white;
}
.data-cad {
    font-weight: bold;
    color: black;
}
.content-nomecompleto   {
    font-size: 10pt;
    margin-top: 0x;
    color: black;
    margin-bottom: 0;
}
.content-estado   {
    font-size: 10pt;
    color: white;
    margin-top: 5px;
    margin-bottom: 0;
}
.content-cidade   {
    font-size: 10pt;
    color: white;
    margin-top: 5px;
    margin-bottom: 0;
}
.content-data   {
    font-size: 10pt;
    margin-top: 5px;
    margin-bottom: 0;
}
.content-hora   {
    font-size: 10pt;
    margin-top: 5px;
    margin-bottom: 0;
}
#btn-edit {
    background: rgb(169, 5, 245);
    padding: 5px;
    color: white;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
    margin-right: 10px;
}
#btn-delete {
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.43);
    padding: 5px;
    color: black;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
}




/* BOX DELETE */
.box-delete {
    width: 350px;
    padding: 30px;
    flex-wrap: wrap;
    margin-top: 140px;
}
.delete-title {
    font-size: 13pt;
    font-weight: bold;
    text-align: center;
    background: rgb(169, 5, 245);
    border-radius: 60px;
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.33);
    padding: 10px;
    color: white;
}
.delete-subtitle {
    font-size: 10pt;
    color: rgba(0, 0, 0, 0.61);
}
#btn1 {
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.23);
    border: 0;
    border-radius: 10px;
}
#btn3 {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: rgb(169, 5, 245);
    border-radius: 60px;
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.33);
    color: white;
}