.bind {
    padding: 0px;
    background-image: url('https://appmonet.com/wp-content/uploads/2020/01/background-part-1-1.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 60%;
    width: 100%;
}
.welcome-title {
    color: rgba(0, 0, 0, 0.686);
    margin-top: 180px;
    margin-bottom: 0px;
    font-size: 30pt;
}
.welcome-subtitle {
    color: rgb(208, 15, 233);
    font-weight: bold;
    font-size: 20pt;
}
.welcome-content {
    color: rgba(0, 0, 0, 0.891);
    font-size: 9pt;
    width: 100%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-top: 20px;
    margin-bottom: 20px;
}
.buffer-img {
    height: 650px;
    margin-top: -20px;
    /* width: 100%; */
}
.next-login {
    width: 110px;
    height: 110px;
    border: 0;
    background: rgb(208, 15, 233);
    color: white;
    border-radius: 10px;
    transition: 0.2s;
    flex-wrap: wrap;
}
.next-login:hover {
    background: rgba(208, 15, 233, 0.901);
    color: white;
    border-radius: 20px;
    transition: 0.2s;
    /* box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.233); */
    /* transform: scale(1.100) */
}


@media (max-width:1200px) {
    .buffer-img {
        height: 550px;
        margin-top: -50px;
    }
    .welcome-title {
        margin-top: 50px;
    }
    .welcome-content {
        width: 100%;
    }
}

/* RSPONSIVE MOBILE */
@media (max-width:992px){ 
    .bind {
        background-size: 150%;
        width: 100%;
    }
    .wrap-bind {
        flex-wrap: wrap;
    }
    .buffer-img {
        width: 100%;
        height: auto;
        margin-top: auto;
    }
    .welcome-title {
        margin-top: 10px;
        color: white;
    }
    .welcome-subtitle {
        color: white;
        font-weight: bold;
        font-size: 20pt;
    }
    .welcome-content {
        margin-top: -10px;
        color: white;
        width: 100%;
        font-size: 10pt;
    }
    .next-login {
        width: 100%;
        border: 0;
        background: rgb(208, 15, 233);
        color: white;
        height: auto;
        margin-top: 100px;
        border-radius: 50px;
        padding: 10px;
        box-shadow: 1px 5px 10px rgba(0,0,0,0.3);
    }
}