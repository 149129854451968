.success {
    background-image: url('https://appmonet.com/wp-content/uploads/2020/01/background-part-1-1.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 110vh;
    width: 100%;
    height: 100vh;
}
.img-success {
    height: 400px;
}
.success-text {
    font-size: 25pt;
    margin-top: 100px;
    /* font-weight: bold; */
    color: white;
}
.wrap-reverse {
    flex-wrap: wrap-reverse;
}
#btn-info-prev {
    margin-left: 15px;
}

@media (max-width:1200px) {
    .success {
        background-image: url('https://appmonet.com/wp-content/uploads/2020/01/background-part-1-1.png');
        background-repeat: no-repeat;
        background-position: top right;
        background-size: 130vh;
        width: 100%;
        height: 100vh;
        padding: 40px;
    }
    .img-success {
        height: 200px;
    }
    .success-text {
        font-size: 20pt;
        margin-top: 10px;
        /* font-weight: bold; */
        color: white;
    }
}

/* RSPONSIVE MOBILE */
@media (max-width:992px){ 
    #btn-info-prev {
        margin-left: 0px;
        width: 100%;
        margin-top: 15px;
    }
}